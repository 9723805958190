<template>
  <v-container  fluid style="margin-top:130px;">
      <v-container>
      
      
    <!-- <v-row fluid>
        <v-col cols="1"></v-col>
        <v-col cols="10">
            <v-btn rounded class="primary_button" @click.native="write=true" style="word-wrap:break-all"  v-html="$t('btn1')"></v-btn><br>
        </v-col>
        <v-col cols="1"></v-col>
    </v-row><br>
    <v-row fluid>
        <v-col cols="1"></v-col>
       <v-col cols="10">
      <v-btn rounded class="primary_button" @click.native="faq=true"  v-html="$t('btn2')"></v-btn><br>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row><br>
    <v-row fluid>
        <v-col cols="1"></v-col>
       <v-col cols="10">
      <v-btn rounded class="primary_button" @click.native="plnm=true" v-html="$t('btn3')"></v-btn><br>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row> -->
    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_vazhl7.png"
         
          @click.native="$router.push({name:'Vazhl_7'})"
         >{{$t('btn7')}}
        </btnsqr></v-col>
    </v-row>
    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_vazhl.png"
         
          @click.native="$router.push({name:'Vazhl_6'})"
         >{{$t('btn6')}}
        </btnsqr></v-col>
    </v-row>
    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_fraud.png"
          
           @click.native="$router.push({name:'Vazhl_1'})"
          >{{$t('btn1')}}
        </btnsqr></v-col>
    </v-row>
    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_snow.png"
         
          @click.native="$router.push({name:'Vazhl_2'})"
         >{{$t('btn2')}}
        </btnsqr></v-col>
    </v-row> 

    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_vazhl3.png"
         
          @click.native="$router.push({name:'Vazhl_3'})"
         >{{$t('btn3')}}
        </btnsqr></v-col>
    </v-row> 
    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_vazhl4.png"
         
          @click.native="$router.push({name:'Vazhl_4'})"
         >{{$t('btn4')}}
        </btnsqr></v-col>
    </v-row> 
    <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_vazhl5.png"
         
          @click.native="$router.push({name:'Vazhl_5'})"
         >{{$t('btn5')}}
        </btnsqr></v-col>
    </v-row> 
     
    
    <!-- <v-row class="ma-1 pa-1" >
      <v-col cols="12" class="ma-1 pa-1"
        ><btnsqr        
          img="img/ico_m_news.png"
          @click.native="$router.push({name:'News_arch'})"
          >{{$t('btn3')}}
        </btnsqr></v-col>
    </v-row> -->

     
      </v-container>

 
      <v-dialog v-model="faq">
      <v-card>
        <v-card-title class=""  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_faq')}}  
        </v-card-title>
       
        <v-card-text>
          <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
      >
        <v-expansion-panel
          v-for="(faq,i) in faqs"
          :key="i"
        >
          <v-expansion-panel-header><b>{{faq.header}}</b></v-expansion-panel-header>
          <v-expansion-panel-content v-html="faq.text" style="padding:15px;">
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="faq = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_write')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write_tp">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_contact')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request_tp"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog v-model="plnm">
        <v-card>
        <!-- <ass_pg :da="da"  :gl="gl" :fm="fm"></ass_pg> -->
        <v-row>
          <v-col cols="12">
            <span><center>Приклад побудови фокуса на Jade Compact Purple</center><br></span>
            <a href="img/v1_1.PNG" target="_blank"><v-img src="img/v1_1.PNG" contain></v-img></a>
            <a href="img/v1_2.PNG" target="_blank"><v-img src="img/v1_2.PNG" contain></v-img></a>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12">
            <span><center>Приклад побудови фокуса на West QS</center><br></span>
            <a href="img/v2_1.PNG" target="_blank"><v-img src="img/v2_1.PNG" contain></v-img></a>
            <a href="img/v2_2.PNG" target="_blank"><v-img src="img/v2_2.PNG" contain></v-img></a>
          </v-col>

        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="plnm = false">{{$t('close')}}</span>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
import btnsqr from "../components/btnsqr.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,btnsqr
  },
  data: () => ({
    faq:false,
    write:false,
    write_tp:false,
    contact:false,
    
    
        accordion: false,
    popout: false,
    inset: false,
    multiple: false,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    plnm:false,
    req_tp:'',
    req_tp_tp:'',
        apiurl:API_LOCATION,
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
      title() {
        return this.$t('title')
      },
      faqs(){
        
        return[
          
          {'header':this.$t('q1'),'text':this.$t('a1')},
          {'header':this.$t('q2'),'text':this.$t('a2')},
          {'header':this.$t('q3'),'text':this.$t('a3')},
          {'header':this.$t('q4'),'text':this.$t('a4')},
          {'header':this.$t('q5'),'text':this.$t('a5')},
          {'header':this.$t('q6'),'text':this.$t('a6')},
          {'header':this.$t('q7'),'text':this.$t('a7')},
          {'header':this.$t('q8'),'text':this.$t('a8')},
          {'header':this.$t('q9'),'text':this.$t('a9')},
          {'header':this.$t('q10'),'text':this.$t('a10')}
        ]},
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
     
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
  },
  methods:{
        save_request: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create', 'question':this.req_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write=false;
            this.req_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

        save_request_tp: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create_tp', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

    }
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Про важливе",
      "btn1":"Як вберегтися від інтернет-шахраїв?",
      "btn2":"Як прожити холодну пору року в умовах військового часу?",
      "btn3":"Як діяти при завалі житлового будинку",
      "btn4":"Як діяти під час обстрілів",
      "btn5":"Як підготувати тривожну валізку правильно",
      "btn6":"Психологічна самодопомога",
      "btn7":"Правила безпеки при відключеннях світла",
      "close":"Закрити",
      "h_write":"Надіслати повідомлення до служби підтримки",
      "h_contact":"Надіслати повідомлення торговому представнику",
      "h_faq":"Відповіді на часті питання",
      "q1":"Чи можу я змінити номер телефону для участі у програмі?",
      "a1":"Так, це можливо. Зверніться, будь ласка, до Вашого Торгового Представника.",

      "q2":"Яку максимальну кількість балів я можу отримати за місяць?",
      "a2":"Максимальна кількість балів, яку можна отримати, залежить від типу Вашого контракту: <br>К300 – 300 балів, <br>К400 – 400 балів,<br>К500 – 500 балів. <br>Виконання екстра завдань дає можливість заробити додаткові бали в  доповнення до стандартної кількості балів контракту.",
      
      "q3":"Я зробив(-ла) помилку при виборі асортименту для участі. Чи можу я його змінити?",
      "a3":"Зміна асортименту для участі доступна раз на один квартал. Для уточнення інформації зверніться, будь ласка, до Вашого Торгового Представника.",

      "q4":"Чи зберігаються на наступний місяць мої накопичені бали?",
      "a4":"Так, зберігаються.",

      "q5":"Мені не нарахували бали за завдання «Фото вітрини» та «Мій товарний запас», у чому причина?",
      "a5":"Усі фото перевіряються вручну модератором, тому модерація може займати декілька днів. Точну інформацію Ви можете дізнатись у свого Торгового Представника.",

      "q6":"Які критерії враховуються при перевірці завдання «Фото вітрини»?",
      "a6":"Критеріями перевірки є  наявність усіх SKU, (відповідно до обраного асортименту) та правильність їх викладки.",

      "q7":"Якщо я беру участь в програмі як мережева торгова точка, скільки SKU має бути на фото вітрини?",
      "a7":"На фото вітрини має бути 32 SKU із актуального асортименту програми (актуальний асортимент уточнюйте у Вашого Торгового Представника).",

      "q8":"Які критерії враховуються при перевірці завдання «Мій товарний запас»?",
      "a8":"Критерієм перевірки є наявність на фото блоку кожного SKU із списку, наведеного у завданні, з нанесеним унікальним кодом.",

      "q9":"Я ввів(-ла) неправильний номер картки Fishka, чи можна його змінити?",
      "a9":"Так, змінити номер карти Fishka можна. Для цього достатньо звернутися у службу тех. підтримки iTeam.",

      "q10":"Як довго чекати на замовлені емоції?",
      "a10":"Поповнення мобільного рахунку та нарахування балів Fishka відбувається одразу (15-20 хвилин) автоматично, замовлені електронні сертифікати «Сільпо», «Єва» та «Розетка» з'являються у застосунку одразу."


      

  }
}
</i18n>